<!--
  PACKAGE_NAME : src\pages\cc\emc\skl\assign.vue
  FILE_NAME : assign
  AUTHOR : ycnam
  DATE : 2024-11-15
  DESCRIPTION : EMC 상담원 스킬 설정
-->
<template>
  <div>
    <Tabs ref="tabs" @selectedIndex="tabSelectedIndex" :tabType="1">
      <Tab title="스킬 기준 관리">
        <div class="flex flex-wrap">
          <div class="flex-initial w-3/10">
            <h2>스킬 목록</h2>
            <esp-dx-data-grid ref="sklManagementSkl" :data-grid="dataGrid.sklManagement.skl"
                              @row-click="onClickSklManagement"/>
          </div>
          <div class="flex-initial w-3/10">
            <h2>스킬 미보유 상담원</h2>
            <esp-dx-data-grid ref="sklManagementNotAssignedAgent" :data-grid="dataGrid.sklManagement.notAssignedAgent"/>
          </div>
          <div class="flex-initial w-2/5">
            <h2>스킬 보유 상담원</h2>
            <esp-dx-data-grid ref="sklManagementAssignedAgent" :data-grid="dataGrid.sklManagement.assignedAgent"/>
          </div>
        </div>
      </Tab>
      <Tab title="상담원 기준 관리">
        <div class="flex flex-wrap">
          <div class="flex-initial w-5/12">
            <h2>상담원 목록</h2>
            <esp-dx-data-grid ref="agentManagementAgent" :data-grid="dataGrid.agentManagement.agent"
                              @row-click="onClickAgtManagement"/>
          </div>
          <div class="flex-initial w-3/12">
            <h2>미보유 스킬</h2>
            <esp-dx-data-grid ref="agentManagementNotAssignedSkl" :key="items.sklGroup"
                              :data-grid="dataGrid.agentManagement.notAssignedSkl"/>
          </div>
          <div class="flex-initial w-4/12">
            <h2>보유 스킬</h2>
            <esp-dx-data-grid ref="agentManagementAssignedSkl" :data-grid="dataGrid.agentManagement.assignedSkl"/>
          </div>
        </div>
      </Tab>
    </Tabs>
    <section class="terms bottom-btn-box pad_t0">
      <div class="page-sub-box">
        <h2 class="hidden">일반 버튼</h2>
        <div class="bottom-btn-wrap">
          <DxButton text="스킬배정" :width="120" :height="40" class="default filled txt_S medium" type="default"
                    @click="onSaving"/>
          <DxButton text="취소" :width="120" :height="40" class="white filled txt_S medium" type="normal"
                    @click="onCanceling"/>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Tab from '@/components/common/tab.vue';
import Tabs from '@/components/common/tabs.vue';
import EspDxDataGrid from '@/components/devextreme/esp-dx-data-grid.vue';
import {DxButton} from 'devextreme-vue/button';
import {isSuccess} from '@/plugins/common-lib';
import {DxSelectBox} from "devextreme-vue/select-box";

export default {
  components: {
    Tabs,
    Tab,
    EspDxDataGrid,
    DxButton,
  },
  data() {
    return {
      items: {
        siteTenant: null,
        sklGroup: null,
        skl: null,
        agent: null,
      },
      selected: {
        siteTenant: null,
        sklGroup: null,
        skl: null,
        agent: null,
      },
      addAgent: [],
      removeAgent: [],
      sklRow: [],
      agtRow: [],
      dataGrid: {
        batch: {
          agent: [],
          skl: [],
          priority: [],
        },
        sklManagement: {
          skl: [],
          notAssignedAgent: [],
          assignedAgent: [],
          addAgent: [],
          removeAgent: [],
          row: null,
        },
        agentManagement: {
          agent: [],
          notAssignedSkl: [],
          assignedSkl: [],
          addSkl: [],
          removeSkl: [],
        },

        // 공통 선언부
        commonGrid: {
          allowColumnResizing: true,       //컬럼 사이즈 허용
          showBorders: true,               //border 유무
          showColumnHeaders: true,         //컬럼 헤더 유무
          showColumnLines: false,          //컬럼 세로선 유무
          showRowLines: true,              //컬럼 가로선 유무
          rowAlternationEnabled: false,
          wordWrapEnabled: false,          //줄바꿈 허용
          dataSource: [],
          customEvent: {
            selectionChanged: false,
            initNewRow: false,
            cellPrepared: false,
            rowPrepared: false,
            contentMenuPreparing: false,
            initialized: false,
            contentReady: true,
            optionChanged: false,
            exporting: false,
            rowInserting: false,
            rowInserted: false,
            rowUpdating: false,
            rowUpdated: false,
            rowRemoved: false,
            rowClick: true,
            saving: false
          },
          showActionButtons: {
            select: false,
            copy: false,
            delete: false,
            customButtons: []                               //pageData에서 vm을 찾지 못하여 페이지에서 직접 설정
          },
          grouping: {
            contextMenuEnabled: false,
            autoExpandAll: false,
            allowCollapsing: true,
            expandMode: 'rowClick'                          // rowClick or buttonClick
          },
          groupPanel: {
            visible: false
          },
          columnChooser: {
            enabled: false                                 // 컬럼 Chooser 버튼 사용유무
          },
          loadPanel: {
            enabled: true                                  // 로딩바 표시 유무
          },
          sorting: {
            mode: 'multiple'                                // single multiple
          },
          scrolling: {                                      // 미사용시 주석처리
             mode: 'standard'                           //스크롤 모드 : ['infinite', 'standard', 'virtual']
          },
          remoteOperations: {                                 // 서버사이드 여부
            filtering: false,
            sorting: false,
            grouping: true,
            paging: false
          },
          paging: {                                           // scrolling 미사용시만 적용됨
            enabled: false,
            pageSize: 10,
            pageIndex: 0                                    // 시작페이지
          },
          pager: {
            visible: false,                                  //페이저 표시 여부
            showPageSizeSelector: false,                    //페이지 사이즈 선택버튼 표시 여부
            allowedPageSizes: [5, 10, 15, 20],              //페이지 사이즈 선택 박스
            displayMode: "full",                         //표시 모드 : ['full', 'compact']
            showInfo: true,                                 //페이지 정보 표시 여부 : full인 경우만 사용 가능
            showNavigationButtons: true                     //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
          },
          filterRow: {
            visible: true,
            value: [],
          },
          headerFilter: {
            visible: true
          },
          editing: {
            allowUpdating: false,                            // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
            allowDeleting: false,
            allowAdding: false,                              // 추가 버튼을 없애고 싶으면 false설정
            mode: 'batch',                                  //수정 모드: ['row', 'cell', 'batch']
            startEditAction: 'click',                       //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
            selectTextOnEditStart: true                    //셀 수정시 텍스트 전체 선택 여부
          },
          selecting: {
            mode: 'multiple',                               //행 단일/멀티 선택 타입 : ['single', 'multiple']
            selectAllMode: 'page',                      //행 선택 허용 범위 : ['allPages', 'page']
            showCheckBoxesMode: 'onClick'                   //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
          },
        },
        commonColumn: {
          alignment: 'center',                         // left center right
          visible: true,
          allowEditing: false,
          sortOrder: 'none',                          // acs desc none
          allowHeaderFiltering: false,
          fixed: false,                               // 컬럼 fix 시 사용
          fixedPosition: 'left',
        },
        commonButton: {
          widget: 'dxButton',
          options: {
            icon: '',
            width: 60,
            height: 30,
          },
          location: 'before',
        },
        commonSelectBox: {
          widget: 'dxSelectBox',
          options: {
            width: 200,
            height: 30,
            stylingMode: 'outlined',
            items: [],
            displayExpr: 'codeNm',
            valueExpr: 'codeValue',
          },
          location: 'before',
        }
      },
    };
  },
  computed: {
    /** @description : 공통코드에서 스킬레벨(priority) 리턴 */
    priority() {
      return this.$_getCode('ers_am_priority');
    },
  },
  methods: {
    onClickSklManagement(row) {
      this.sklRow = row;
      this.getAgtSklList(row.data.skillId);
    },
    onClickAgtManagement(row) {
      this.agtRow = row;
      this.getAgentSklList(row.data.agentId);
    },
    /** @description : 선택된 탭 인덱스 */
    tabSelectedIndex(index) {
      this.addAgent = [];
      this.removeAgent = [];
      if(!index){
        this.onClickSklManagement(this.sklRow);
      }else{
        this.onClickAgtManagement(this.agtRow);
      }
    },
    /** @description: 스킬배정 저장 로직 */
    async onSaving(e) {
      const deletePayload = {
        actionname: "EMC_SKL_AGT_DELETE",
        data: this.removeAgent,
        useErrorPopup: true,
        loading: true,
      }

      const delRes = await this.CALL_EMC_API(deletePayload);
      if (isSuccess(delRes)) {
        this.removeAgent = [];
      }

      const savePayload = {
        actionname: "EMC_SKL_AGT_MERGE",
        data: this.addAgent,
        useErrorPopup: true,
        loading: true,
      }

      const res = await this.CALL_EMC_API(savePayload);
      if (isSuccess(res)) {
        this.$_Toast(this.$_msgContents('COMMON.MESSAGE.CMN_SUC_SAVE', {defaultValue: '정상적으로 저장되었습니다.'}));
        this.addAgent = [];
      }
    },
    /** @description: 취소 이벤트 */
    onCanceling(e) {
      this.addAgent = [];
      this.removeAgent = [];
      this.agtRow = null;
      this.sklRow = null;
    },
    addSklData(agt) {
      const skill = this.$refs.sklManagementSkl.selectedRowsData;
      return agt.map(agent => {
        return {...agent, skillId: skill[0].skillId, skillName: skill[0].skillName, description: skill[0].description, priority: '1'};
      });
    },
    addAgtData(skl) {
      const agt = this.$refs.agentManagementAgent.selectedRowsData;
      return skl.map(skill => {
        return {...skill, siteId: agt[0].siteId, tenantId: agt[0].tenantId, agentId: agt[0].agentId, agentName: agt[0].agentName, priority: '1'};
      });
    },
    /** @description : data grid 초기화 */
    initDataGrid() {
      const vm = this;
      const commonGrid = this.dataGrid.commonGrid;
      const commonColumn = this.dataGrid.commonColumn;
      const commonButton = this.dataGrid.commonButton;

      this.dataGrid.sklManagement.skl = {
        ...commonGrid,
        refName: 'sklManagementSkl',
        columns: [
          {...commonColumn, caption: 'ID', dataField: 'skillId',},
          {...commonColumn, caption: '스킬', dataField: 'skillName',},
          {...commonColumn, caption: '스킬명', dataField: 'description',},
        ],
        selecting: {
          mode: 'single', //행 단일/멀티 선택 타입 : ['single', 'multiple']
          selectAllMode: 'allPages', //행 선택 허용 범위 : ['allPages', 'page']
          showCheckBoxesMode: 'onClick' //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
        },
      };

      this.getSklList(); // 스킬 목록

      this.dataGrid.sklManagement.notAssignedAgent = {
        ...commonGrid,
        refName: 'sklManagementNotAssignedAgent',
        columns: [
          {...commonColumn, caption: '상담원ID', dataField: 'agentId', width: '50%'},
          {...commonColumn, caption: '미보유상담원', dataField: 'agentName', width: '50%'},
        ],
        paging: { // scrolling 미사용시만 적용됨
          enabled: false,
          pageSize: 10,
          pageIndex: 0 // 시작페이지
        },
        showActionButtons: {
          ...commonGrid.showActionButtons,
          delete: false,
          customButtons: [
            {
              ...commonButton,
              options: {
                ...commonButton.options,
                text: '선택 배정',
                width: 100,
                elementAttr: {class: 'btn_XS default filled'},
                onClick(e) {
                  vm.setSelectedSkl(e);
                },
              },
            },
            {
              ...commonButton,
              options: {
                ...commonButton.options,
                text: '모두 배정',
                width: 100,
                elementAttr: {class: 'btn_XS white light_filled'},
                onClick(e) {
                  vm.setAllSkl(e);
                },
              },
            },
          ],
        },
      };
      this.dataGrid.sklManagement.assignedAgent = {
        ...commonGrid,
        refName: 'sklManagementAssignedAgent',
        columns: [
          {...commonColumn, caption: '배정상담원 ID', dataField: 'agentId', width: '33%'},
          {...commonColumn, caption: '배정상담원 이름', dataField: 'agentName', width: '33%'},
          {
            caption: '스킬레벨',
            cellTemplate: (container, options) => {
              const selectBox = new DxSelectBox({
                propsData: {
                  items: this.priority,
                  placeholder: '선택',
                  displayExpr: 'codeNm',
                  valueExpr: 'codeValue',
                  value: options.data.priority != null ? options.data.priority : '1',
                  elementAttr: {
                    class: 'check-type col mar_le15',
                  },
                  stylingMode: this.stylingMode,
                  onValueChanged: e => {
                    options.data.priority = e.value;
                    const index = this.addAgent.findIndex(agent => agent.agentId === options.data.agentId);
                    if (index !== -1) {
                      this.addAgent.splice(index, 1, options.data);
                    } else {
                      this.addAgent.push(options.data);
                    }
                  },
                },
              }).$mount();
              container.appendChild(selectBox.$el);
            },
          },
        ],
        paging: { // scrolling 미사용시만 적용됨
          enabled: true,
          pageSize: 10,
          pageIndex: 0 // 시작페이지
        },
        showActionButtons: {
          ...commonGrid.showActionButtons,
          delete: false,
          customButtons: [
            {
              ...commonButton,
              options: {
                ...commonButton.options,
                text: '선택 미보유',
                width: 100,
                elementAttr: {class: 'btn_XS default filled'},
                onClick(e) {
                  vm.removeSelectedSkl(e);
                },
              },
            },
            {
              ...commonButton,
              options: {
                ...commonButton.options,
                text: '모두 미보유',
                width: 100,
                elementAttr: {class: 'btn_XS white light_filled'},
                onClick(e) {
                  vm.removeAllSkl(e);
                },
              },
            },
          ],
        },
      };

      this.dataGrid.agentManagement.agent = {
        ...commonGrid,
        refName: 'agentManagementAgent',
        columns: [
          {...commonColumn, caption: '상담원 ID', dataField: 'agentId',},
          {...commonColumn, caption: '상담원 이름', dataField: 'agentName',},
        ],
        selecting: {
          mode: 'single',                               //행 단일/멀티 선택 타입 : ['single', 'multiple']
          selectAllMode: 'allPages',                      //행 선택 허용 범위 : ['allPages', 'page']
          showCheckBoxesMode: 'onClick'                   //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
        },
      };

      this.dataGrid.agentManagement.notAssignedSkl = {
        ...commonGrid,
        refName: 'agentManagementNotAssignedSkl',
        columns: [
          {...commonColumn, caption: '미보유스킬', dataField: 'skillName',},
        ],
        paging: { // scrolling 미사용시만 적용됨
          enabled: true,
          pageSize: 10,
          pageIndex: 0 // 시작페이지
        },
        showActionButtons: {
          ...commonGrid.showActionButtons,
          delete: false,
          customButtons: [
            {
              ...commonButton,
              options: {
                ...commonButton.options,
                text: '선택 배정',
                width: 100,
                elementAttr: {class: 'btn_XS default filled'},
                onClick(e) {
                  vm.setSelectedAgt(e);
                },
              },
            },
            {
              ...commonButton,
              options: {
                ...commonButton.options,
                text: '모두 배정',
                width: 100,
                elementAttr: {class: 'btn_XS white light_filled'},
                onClick(e) {
                  vm.setAllAgt(e);
                },
              },
            },
          ],
        },
      };

      this.dataGrid.agentManagement.assignedSkl = {
        ...commonGrid,
        refName: 'agentManagementAssignedSkl',
        columns: [
          {...commonColumn, caption: '보유스킬', dataField: 'skillName',},
          {
            caption: '스킬레벨',
            cellTemplate: (container, options) => {
              const selectBox = new DxSelectBox({
                propsData: {
                  items: this.priority,
                  placeholder: '선택',
                  displayExpr: 'codeNm',
                  valueExpr: 'codeValue',
                  value: options.data.priority != null ? options.data.priority : '1',
                  elementAttr: {
                    class: 'check-type col mar_le15',
                  },
                  stylingMode: this.stylingMode,
                  onValueChanged: e => {
                    options.data.priority = e.value;
                    const index = this.addAgent.findIndex(agent => agent.skillId === options.data.skillId);
                    if (index !== -1) {
                      this.addAgent.splice(index, 1, options.data);
                    } else {
                      this.addAgent.push(options.data);
                    }
                  },
                },
              }).$mount();
              container.appendChild(selectBox.$el);
            },
          },
        ],
        paging: { // scrolling 미사용시만 적용됨
          enabled: true,
          pageSize: 10,
          pageIndex: 0 // 시작페이지
        },
        showActionButtons: {
          ...commonGrid.showActionButtons,
          delete: false,
          customButtons: [
            {
              ...commonButton,
              options: {
                ...commonButton.options,
                text: '선택 미보유',
                width: 100,
                elementAttr: {class: 'btn_XS default filled'},
                onClick(e) {
                  vm.removeSelectedAgt(e);
                },
              },
            },
            {
              ...commonButton,
              options: {
                ...commonButton.options,
                text: '모두 미보유',
                width: 100,
                elementAttr: {class: 'btn_XS white light_filled'},
                onClick(e) {
                  vm.removeAllAgt(e);
                },
              },
            },
          ],
        },
      };
    },
    /** @description : 스킬 목록 조회 */
    async getSklList() {
      const payload = {
        actionname: "EMC_SKL_LIST",
        useErrorPopup: true,
        loading: true,
      }

      const res = await this.CALL_EMC_API(payload);
      if (isSuccess(res)) {
        this.dataGrid.sklManagement.skl.dataSource = res.data.data;
        this.$refs.sklManagementSkl.paging.totalCount = res.data.data.length;
      }
    },
    async getAgtSklList(id) {
      const payload = {
        actionname: "EMC_SKL_AGT_LIST",
        data: {skillId: id},
        useErrorPopup: true,
        loading: true,
      }
      const res = await this.CALL_EMC_API(payload);
      
      // EMC에서는 스킬id도 같이 넘겨받아야 돼서 추가해주기
      for (let data of res.data.data) {
        data.skillId = id;
      }

      if (isSuccess(res)) {
        this.dataGrid.sklManagement.notAssignedAgent.dataSource = res.data.data.filter(item => item.flag === 'N');
        this.dataGrid.sklManagement.assignedAgent.dataSource = res.data.data.filter(item => item.flag === 'Y');
        this.$refs.sklManagementNotAssignedAgent.paging.totalCount = this.dataGrid.sklManagement.notAssignedAgent.dataSource.length;
        this.$refs.sklManagementAssignedAgent.paging.totalCount = this.dataGrid.sklManagement.assignedAgent.dataSource.length;
      }
    },
    /** @description : 스킬 기준 관리 > 선택 배정 */
    setSelectedSkl(e) {
      const selectedRows = this.addSklData(this.$refs.sklManagementNotAssignedAgent.selectedRowsData);
      this.dataGrid.sklManagement.assignedAgent.dataSource = [...selectedRows, ...this.dataGrid.sklManagement.assignedAgent.dataSource];
      const deletedRows = this.dataGrid.sklManagement.notAssignedAgent.dataSource.filter(agent =>
        !selectedRows.some(selectedRow => selectedRow.agentId === agent.agentId));
      this.dataGrid.sklManagement.notAssignedAgent.dataSource = deletedRows;
      this.addAgent = [...this.addAgent, ...selectedRows];
    },
    /** @description : 스킬 기준 관리 > 모두 배정 */
    setAllSkl(e) {
      this.dataGrid.sklManagement.assignedAgent.dataSource = [...this.dataGrid.sklManagement.notAssignedAgent.dataSource, ...this.dataGrid.sklManagement.assignedAgent.dataSource];
      this.dataGrid.sklManagement.notAssignedAgent.dataSource = [];
      this.addAgent = [...this.addAgent, ...this.dataGrid.sklManagement.assignedAgent.dataSource];
    },
    /** @description : 스킬 기준 관리 > 선택 미보유 */
    removeSelectedSkl(e) {
      const selectedRows = this.$refs.sklManagementAssignedAgent.selectedRowsData;
      this.dataGrid.sklManagement.notAssignedAgent.dataSource = [...selectedRows, ...this.dataGrid.sklManagement.notAssignedAgent.dataSource];
      const deletedRows = this.dataGrid.sklManagement.assignedAgent.dataSource.filter(agent =>
        !selectedRows.some(selectedRow => selectedRow.agentId === agent.agentId));
      this.dataGrid.sklManagement.assignedAgent.dataSource = deletedRows;
      this.removeAgent = [...this.removeAgent, ...selectedRows];
    },
    /** @description : 스킬 기준 관리 > 모두 미보유 */
    removeAllSkl(e) {
      this.dataGrid.sklManagement.notAssignedAgent.dataSource = [...this.dataGrid.sklManagement.assignedAgent.dataSource, ...this.dataGrid.sklManagement.notAssignedAgent.dataSource];
      this.dataGrid.sklManagement.assignedAgent.dataSource = [];
      this.removeAgent = [...this.removeAgent, ...this.dataGrid.sklManagement.notAssignedAgent.dataSource];
    },
    /** @description : 상담원 기준 관리 > 선택 배정 */
    setSelectedAgt(e) {
      const selectedRows = this.addAgtData(this.$refs.agentManagementNotAssignedSkl.selectedRowsData);
      this.dataGrid.agentManagement.assignedSkl.dataSource = [...selectedRows, ...this.dataGrid.agentManagement.assignedSkl.dataSource];
      const deletedRows = this.dataGrid.agentManagement.notAssignedSkl.dataSource.filter(agent =>
        !selectedRows.some(selectedRow => selectedRow.skillId === agent.skillId));
      this.dataGrid.agentManagement.notAssignedSkl.dataSource = deletedRows;
      this.addAgent = [...this.addAgent, ...selectedRows];
    },
    /** @description : 상담원 기준 관리 > 모두 배정 */
    setAllAgt(e) {
      this.dataGrid.agentManagement.assignedSkl.dataSource = [...this.dataGrid.agentManagement.notAssignedSkl.dataSource, ...this.dataGrid.agentManagement.assignedSkl.dataSource];
      this.dataGrid.agentManagement.notAssignedSkl.dataSource = [];
      this.addAgent = [...this.addAgent, ...this.dataGrid.agentManagement.assignedSkl.dataSource];
    },
    /** @description : 상담원 기준 관리 > 선택 미보유 */
    removeSelectedAgt(e) {
      const selectedRows = this.$refs.agentManagementAssignedSkl.selectedRowsData;
      this.dataGrid.agentManagement.notAssignedSkl.dataSource = [...selectedRows, ...this.dataGrid.agentManagement.notAssignedSkl.dataSource];
      const deletedRows = this.dataGrid.agentManagement.assignedSkl.dataSource.filter(agent =>
        !selectedRows.some(selectedRow => selectedRow.skillId === agent.skillId));
      this.dataGrid.agentManagement.assignedSkl.dataSource = deletedRows;
      this.removeAgent = [...this.removeAgent, ...selectedRows];
    },
    /** @description : 상담원 기준 관리 > 모두 미보유 */
    removeAllAgt(e) {
      this.dataGrid.agentManagement.notAssignedSkl.dataSource = [...this.dataGrid.agentManagement.assignedSkl.dataSource, ...this.dataGrid.agentManagement.notAssignedSkl.dataSource];
      this.dataGrid.agentManagement.assignedSkl.dataSource = [];
      this.removeAgent = [...this.removeAgent, ...this.dataGrid.agentManagement.notAssignedSkl.dataSource];
    },
    async setAgentList() {
      const payload = {
        actionname: "EMC_AGT_LIST",
        useErrorPopup: true,
        loading: true,
        data: {
          deleted: 'N',
        },
      }
      const res = await this.CALL_EMC_API(payload);
      if (isSuccess(res)) {
        this.dataGrid.agentManagement.agent.dataSource = res.data.data;
        this.$refs.agentManagementAgent.paging.totalCount = res.data.data.length;
      }
    },
    async getAgentSklList(id) {
      const payload = {
        actionname: "EMC_AGT_SKL_LIST",
        data: {agentId: id},
        useErrorPopup: true,
        loading: true,
      }
      const res = await this.CALL_EMC_API(payload);
      
      // EMC에서는 상담원id도 같이 넘겨받아야 돼서 추가해주기
      for (let data of res.data.data) {
        data.agentId = id;
      }      

      if (isSuccess(res)) {
        this.dataGrid.agentManagement.notAssignedSkl.dataSource = res.data.data.filter(item => item.flag === 'N');
        this.dataGrid.agentManagement.assignedSkl.dataSource = res.data.data.filter(item => item.flag === 'Y');
        this.$refs.agentManagementNotAssignedSkl.paging.totalCount = this.dataGrid.agentManagement.notAssignedSkl.dataSource.length;
        this.$refs.agentManagementAssignedSkl.paging.totalCount = this.dataGrid.agentManagement.assignedSkl.dataSource.length;
      }
    },
  }, // methods
  async created() {
    await this.initDataGrid();
    await this.setAgentList();
  },
};
</script>

<style lang="scss" scoped>
.flex-initial {
  padding: 30px 20px 0 0;
}

.flex-initial h2 {
  font-size: 18px;
  color: #545454;
  font-weight: 500;
}
</style>